.text-small {
font-size: 10px;
}

.text-dashboard {
font-size: 12px;
}

code {
      background-color: #f4f4f4;
      padding: 2px 6px;
      border-radius: 3px;
    }
    @media (max-width: 767px) {
  .sidebar {
  left: -350px; 
  transition: left 0.3s ease-in-out; }
.sidebar.open {
  left: 0; }
}


